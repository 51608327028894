import React, { useEffect } from "react";

const GoogleTranslateWidget = (props) => {
  const googleTranslateElementInit = () => {
    const { googleTranslateLanguages,dealerinfo } = props;
    
    const translateConfig = googleTranslateLanguages && googleTranslateLanguages !== ''
      ? {
          pageLanguage: "en",
          includedLanguages: googleTranslateLanguages,
          layout: window.google.translate.TranslateElement,
        }
      : {
          pageLanguage: "en",
          layout: window.google.translate.TranslateElement,
        };

    new window.google.translate.TranslateElement(translateConfig, "google_translate_element");
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute("src", "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit");
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;

    const gtButton = document.querySelector("#google_translate_element");
    document.body.addEventListener("click", function (event) {
      if (gtButton.contains(event.target)) {
        event = event || window.event;
        event.preventDefault();
      }
    });
  }, []);

  return <div id="google_translate_element"></div>;
};

export default GoogleTranslateWidget;
