import React, { useEffect, useState, useRef } from "react"
import { Link } from "gatsby"
import view from "./bottommessage.module.scss"

export default function BottomMessage(props) {
    useEffect(() => {
        if (window !== 'undefined'){
            window.openBottomMessage = openBodyMessage
        }
    });

    const ref = useRef();
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                hideBodyMessage()
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [ ref ]);

    const [textHeader, setHeader] = useState('')
    const [bodyText, setBodyText] = useState('')
    const [showModal, setShowModal] = useState(false)

    function setMessage(newHeader, newBody){
        setHeader(newHeader)
        setBodyText(newBody)
    }

    function openBodyMessage(newHeader, newBody){
        setShowModal(true)
        setHeader(newHeader)
        setBodyText(newBody)
    }

    function hideBodyMessage(){
        setShowModal(false)
    }

    return (
        <div ref={ref}>
            <div className={showModal ? [view["bottom-message"], view["show"]].join(' ') : view["bottom-message"]}>
                <h2>{textHeader}</h2>
                <div className={view["disclaimer-close-button"]} onClick={hideBodyMessage}></div>
                <p>{bodyText}</p>
            </div>
        </div>
    )
}

BottomMessage.defaultProps = {
    visible: true,
    closeModal: () => { }
}
